import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Field, FinishedQuestions, FormConfig, FormFieldOptions, FormSubmit } from "../types/types";
import AbsoluteLogo from "../images/absolute-logo.png";
import FormTraverser from "../functions/FormTraverser";
import Address from "./Address";
import ButtonChoice from "./ButtonChoice";
import Checkbox from "./Checkbox";
import Choice from "./Choice";
import Text from "./Text";
import Currency from "./Currency";
import Email from "./Email";
import NumberInput from "./NumberInput";
import PhoneNumber from "./PhoneNumber";
import PreLoader from "./PreLoader";
import Radio from "./Radio";
import Selector from "./Selector";
import { evaluateFormResponses } from "../functions/ScoringFunctions";
import agent from "../api/Agent";
import { toast, Bounce, ToastContainer } from "react-toastify";

//css
import "react-toastify/dist/ReactToastify.css";

interface TextValues {
  firstName: string;
  surname: string;
  phone: string;
  number: string;
  email: string;
  [key: number]: string;
}

interface FormData {
  key: any;
  value: any;
}

interface AddressValues {
  line1: string;
  line2: string;
  line3: string;
  line4: string;
  postcode: string;
  country: string;
}

interface FormProps {
  setBackgroundImage: React.Dispatch<React.SetStateAction<string>>;
  setApiKey: React.Dispatch<React.SetStateAction<string>>;
}

const defaultColorConfig = {
  pageBackgroundColor: "#fff",
  formBackgroundColor: "#fff",
  formQuestionColor: "#000",
  answerTextColor: "#000",
  answerBoxBackgroundColor: "#fff",
  controlBtnBackgroundColor: "#000",
  controlBtnTextColor: "#fff",
  controlBtnHoverTextColor: "#fff",
  controlBtnHoverBackgroundColor: "#595656",
};

export default function Form({ setBackgroundImage, setApiKey }: FormProps) {
  const { apiKey, formId, uuid, applicantId } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [formConfig, setFormConfig] = useState<FormConfig | null>(null);
  const [traverser, setTraverser] = useState<FormTraverser | null>(null);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [currentQuestion, setCurrentQuestion] = useState(traverser?.getCurrentQuestion());
  const [answer, setAnswer] = useState<string>("");
  const [disableNext, setDisableNext] = useState(true);

  const { formFieldType, label, description, isRequired } = currentQuestion || ({} as Field);

  // for scoring
  const [finishedQuestions, setFinishedQuestions] = useState<FinishedQuestions[]>([]);

  // Form Data
  const [formData, setFormData] = useState<FormData[]>([]);
  const [textValues, setTextValues] = useState<TextValues>({} as TextValues);
  const [addressValues, setAddressValues] = useState<AddressValues>({
    line1: "",
    line2: "",
    line3: "",
    line4: "",
    postcode: "",
    country: "Select Country",
  });
  const [clearNumberValue, setClearNumberValue] = useState(false);

  useEffect(() => {
    if (apiKey) setApiKey(apiKey);
  }, [apiKey]);

  // Fetch our form data
  useEffect(() => {
    setLoading(true);
    if (formId && apiKey) {
      const epochTime = Date.now();
      agent.formConfigApi
        .get(formId, uuid, applicantId, epochTime, apiKey)
        .then((response) => {
          setFormConfig(response);
          const newTraverser = new FormTraverser(response);
          setTraverser(newTraverser);
          setCurrentQuestion(newTraverser.getCurrentQuestion());
          setBackgroundImage(response.pageBackgroundImage);
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Failed to fetch form");
          console.error("Error:", error);
        });
    }
  }, [formId, apiKey]);

  useEffect(() => {
    if (formConfig) {
      setLoading(false);
    }
  }, [formConfig]);

  useEffect(() => {
    const question = traverser?.getCurrentQuestion();
    if (question && question.formFieldType.type === "buttonChoice") {
      validateAndProceed();
    }
  }, [formData]);

  useEffect(() => {
    if (currentQuestion?.formFieldType.type === "buttonChoice") {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
  }, [currentQuestion?.formFieldType.type]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (!disableNext) {
          const nextButton = document.getElementById("nextButton") as HTMLButtonElement;
          nextButton.click();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [disableNext]);

  const getPriorityId = (answer: string, fieldOptions?: FormFieldOptions[] | null, questionId?: number) => {
    if (fieldOptions) {
      for (let i = 0; i < fieldOptions.length; i++) {
        if (fieldOptions[i].isSpecialCase && fieldOptions[i].optionLabel.toLowerCase() === answer.toLowerCase()) {
          let returnObject = {
            priorityOptionId: fieldOptions[i].priorityOptionId,
            isSpecialCase: fieldOptions[i].isSpecialCase,
            customSuccessMessage: fieldOptions[i].successMessage ? fieldOptions[i].successMessage : null,
          };
          return returnObject;
        }
      }
    }

    return null;
  };

  const handleFieldChange = (value: any) => {
    const key = currentQuestion?.id;
    setFormData((prev) => {
      const existingIndex = prev.findIndex((item) => item.key === key);

      if (existingIndex !== -1) {
        const updatedFormData = [...prev];
        updatedFormData[existingIndex] = { key, value };
        return updatedFormData;
      }

      return [...prev, { key: key, value: value }];
    });
  };

  const handleCheckboxChange = (values: string[]) => {
    const newValue = values.join(", ") || "";
    handleFieldChange(newValue);
  };

  const handleButtonChoiceClick = (value: any) => {
    handleFieldChange(value);
  };

  useEffect(() => {
    if (addressValues.country !== "Select Country") {
      if (addressValues.line4) {
        handleFieldChange(
          `${addressValues.line1},${addressValues.line2},${addressValues.line3},${addressValues.line4},${addressValues.postcode},${addressValues.country}`
        );
      } else {
        handleFieldChange(
          `${addressValues.line1},${addressValues.line2},${addressValues.line3},${addressValues.postcode},${addressValues.country}`
        );
      }
    }
  }, [addressValues]);

  const handleEmailChange = (value: string, fieldName: string) => {
    handleTextChange(value, fieldName);
    handleFieldChange(value);
  };

  const handlePhoneNumberChange = (value: string, fieldName: string) => {
    handleFieldChange(value);
    handleTextChange(value, fieldName);
  };

  const handleTextChange = (value: string, fieldName: string) => {
    setTextValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
    handleFieldChange(value);
  };

  const handleAddressChange = (updatedAddress: any) => {
    setAddressValues(updatedAddress);
  };

  const validateAndProceed = () => {
    // If the field is required and not valid show our required text
    if (!isInputValid() && isRequired) {
      var requiredAlert = document.querySelector(".required_field");

      if (requiredAlert) {
        requiredAlert.classList.add("show");

        // Remove the required text after 3 seconds
        setTimeout(() => {
          requiredAlert?.classList.remove("show");
        }, 3000);
      }

      return;
    }

    handleNext();
  };

  const isInputValid = () => {
    var currentValue = getCurrentInputValue();
    if (formFieldType.type === "address") {
      return isAddressValid();
    }

    let isValid = false;

    if (formFieldType.type === "currency" || formFieldType.type === "number") {
      isValid = isRequired ? currentValue : true;
    } else {
      // Check here if we have a valid string
      isValid = isRequired ? currentValue && currentValue.trim() !== "" : true;
    }
    return isValid;
  };

  // Specific function to check if address is valid
  const isAddressValid = () => {
    const { line1, line2, line3, postcode, country } = addressValues;

    return (
      line1.trim() !== "" &&
      line2.trim() !== "" &&
      line3.trim() !== "" &&
      postcode.trim() !== "" &&
      country !== "Select Country"
    );
  };

  // Function to get the current input value based on the form field type
  const getCurrentInputValue = () => {
    switch (formFieldType?.type) {
      case "selectbox":
      case "salutation":
      case "firstname":
      case "surname":
      case "email":
      case "phone":
      case "textarea":
      case "checkbox":
      case "yes_no":
      case "date":
      case "choice":
      case "number":
      case "buttonChoice":
      case "currency":
        return getFormDataValue();
      case "address":
        const addressString = getAddressString();
        const cleanedAddress = addressString.replace(/,/g, "").trim();
        return cleanedAddress === "" ? "" : addressString;
      default:
        return "";
    }
  };

  const getFormDataValue = () => {
    const field = formData.find((item) => item.key === currentQuestion?.id);
    return field ? field.value : null;
  };

  const getAddressString = (): string => {
    const { line1, line2, line3, line4, postcode, country } = addressValues;

    const isAddressFilled = [line1, line2, line3, postcode].filter((value) => value.trim() !== "").length >= 4;
    const isCountrySelected = country !== "Select Country";

    const line4Entered = line4.trim() !== "" ? `${line4}, ` : "";

    if (isAddressFilled && isCountrySelected) {
      return `${line1}, ${line2}, ${line3}, ${line4Entered}${postcode}, ${country}`;
    }

    return "";
  };

  const handleNextButtonClick = (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    // If the field is required and not valid show our required text
    if (!isInputValid() && isRequired) {
      var requiredAlert = document.querySelector(".required_field");

      if (requiredAlert) {
        requiredAlert.classList.add("show");

        // Remove the required text after 3 seconds
        setTimeout(() => {
          requiredAlert?.classList.remove("show");
        }, 3000);
      }

      return;
    }
    handleNext();
  };

  // Handle moving to the next question
  const handleNext = () => {
    if (currentQuestion) {
      const answer = getFormDataValue();
      var priorityResponseObject = getPriorityId(answer, currentQuestion?.formFieldOptions, currentQuestion?.id);

      setFinishedQuestions((prev) => {
        const existingIndex = prev.findIndex((item) => item.questionId === currentQuestion?.id);

        if (existingIndex !== -1) {
          const existingQuestionId = prev[existingIndex].questionId;
          const updatedData = [...prev];
          updatedData[existingIndex] = {
            questionId: existingQuestionId,
            answer: answer,
            priorityOptionId: priorityResponseObject?.priorityOptionId
              ? priorityResponseObject?.priorityOptionId
              : null,
            isSpecialCase: priorityResponseObject?.isSpecialCase ? priorityResponseObject?.isSpecialCase : false,
            customSuccessMessage: priorityResponseObject?.customSuccessMessage,
          };
          return updatedData;
        }
        return [
          ...finishedQuestions,
          {
            questionId: currentQuestion?.id ? currentQuestion?.id : 1,
            answer: answer,
            priorityOptionId: priorityResponseObject?.priorityOptionId
              ? priorityResponseObject?.priorityOptionId
              : null,
            isSpecialCase: priorityResponseObject?.isSpecialCase ? priorityResponseObject?.isSpecialCase : false,
            customSuccessMessage: priorityResponseObject?.customSuccessMessage,
          },
        ];
      });

      if (formFieldType?.type === "number" || "currency") setClearNumberValue(true);

      const nextQuestion = traverser?.forward(answer);
      setCurrentQuestion(nextQuestion);

      setAnswer(""); // Reset answer for the next question
    }
  };

  // Handle moving to the previous question
  const handlePrevious = () => {
    finishedQuestions.pop();
    if (currentQuestion && currentQuestion.formFieldType.type === "currency") {
      setClearNumberValue(true);
    }
    const prevQuestion = traverser?.back();
    setCurrentQuestion(prevQuestion);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && currentQuestion !== undefined) handleSubmit();
  }, [currentQuestion]);

  const handleSubmit = () => {
    let formComplete = false;

    if (currentQuestion === null && formData.length > 0) {
      formComplete = true;
    }

    let postData: FormSubmit = {
      FirstName: textValues.firstName || "",
      LastName: textValues.surname || "",
      Address: getAddressString(),
      TelephoneNumber: textValues.phone || "",
      MobileTelephoneNumber: textValues.number || "",
      BranchName: null,
      Message: formConfig?.successMessage ? formConfig?.successMessage : "Thank you",
      Campaign: formId || null,
      Email: formConfig?.emailAddress ? formConfig.emailAddress : textValues.email ? textValues.email : null,
      // Email: textValues.email || "",
      Data: formData,
      Score: null,
      Priority: null,
      FormCompleted: formComplete,
      UUID: uuid ? uuid : null,
      ApplicantId: applicantId ? applicantId : null,
    };

    if (!postData.Email) return;

    if (!postData.Email) return;

    if (formConfig?.scoring && formConfig?.scoring.length > 0 && formConfig?.formScoringMatrix) {
      const score = evaluateFormResponses(
        finishedQuestions,
        formConfig?.formScoringMatrix.priorities,
        formConfig?.scoring,
        formComplete
      );

      postData.Score = score.finalScore;
      postData.Priority = score.priority;
      postData.Message = score.successMessage!;
    }
    if (apiKey) {
      const modal = document.querySelector(".modal");
      const overlay = document.querySelector(".modal_overlay");
      const closeBtn = document.querySelector(".close_icon");
      const tryAgain = document.querySelector(".try_again_btn");

      agent.formConfigApi
        .post(postData, apiKey)
        .then(() => {
          const successMessage = postData.Message || null;
          const finalScore = postData.Score;
          const priority = postData.Priority;
          const redirectUrl = formConfig?.redirectUrl;
          const downloadUrl = formConfig?.downloadUrl;
          const logoUrl = formConfig?.logoUrl;

          if (formComplete !== undefined && formComplete) {
            navigate("/success", {
              state: {
                successMessage,
                redirectUrl,
                downloadUrl,
                finalScore,
                priority,
                logoUrl,
              },
            });
          }
        })
        .catch((error) => {
          if (modal && overlay) {
            modal.classList.add("show");
            overlay.classList.add("show");
          }

          const closeModal = function () {
            modal?.classList.remove("show");
            overlay?.classList.remove("show");
          };

          closeBtn?.addEventListener("click", closeModal);
          tryAgain?.addEventListener("click", closeModal);

          console.error("Failed to post data:", error);
        });
    }
  };

  if (loading) {
    return (
      <div>
        <div className="App">
          <h1>Loading...</h1>
          <PreLoader isLoading={loading} />
        </div>
      </div>
    );
  }

  return (
    <>
      <div>
        {currentQuestion ? (
          <div>
            <div className="main__container">
              <div className="image__container">
                <img src={formConfig?.logoUrl ? formConfig?.logoUrl : AbsoluteLogo} alt="absolute-logo"></img>
              </div>
              <div className="sub__container">
                <form>
                  <div
                    className="info-entry__container"
                    style={{
                      background: formConfig?.colorConfig?.formBackgroundColor
                        ? formConfig?.colorConfig?.formBackgroundColor
                        : defaultColorConfig.formBackgroundColor,
                      border: `3px solid ${
                        formConfig?.colorConfig?.formQuestionColor
                          ? formConfig?.colorConfig?.formQuestionColor
                          : defaultColorConfig.formQuestionColor
                      }`,
                    }}>
                    <div className="info__entry">
                      <div className="question__container">
                        <div
                          className="info__title"
                          id="question"
                          style={{
                            color: formConfig?.colorConfig?.formQuestionColor
                              ? formConfig?.colorConfig?.formQuestionColor
                              : defaultColorConfig.formQuestionColor,
                          }}>
                          {label}
                          {formConfig?.showIsRequiredIndicator && <span>*</span>}
                        </div>
                      </div>
                      <div className="required_field">
                        <h1>This Field is Required</h1>
                      </div>
                      <div
                        className="info__dropdown"
                        style={{
                          background: formConfig?.colorConfig?.formBackgroundColor
                            ? formConfig?.colorConfig?.formBackgroundColor
                            : defaultColorConfig.formBackgroundColor,
                        }}>
                        {}
                        {(() => {
                          switch (formFieldType?.type) {
                            case "salutation":
                            case "selectbox":
                              return (
                                <Selector
                                  options={currentQuestion?.formFieldOptions || []}
                                  selectedValue={getFormDataValue() || "Please Select"}
                                  onValueChange={(value) => handleFieldChange(value)}
                                />
                              );
                            case "firstname":
                              return (
                                <Text
                                  value={getFormDataValue() || ""}
                                  onTextChange={(value) => handleTextChange(value, "firstName")}
                                  autoComplete="given-name"
                                  borderColor={
                                    formConfig?.colorConfig?.formQuestionColor
                                      ? formConfig?.colorConfig?.formQuestionColor
                                      : defaultColorConfig.formQuestionColor
                                  }
                                />
                              );
                            case "surname":
                              return (
                                <Text
                                  value={getFormDataValue() || ""}
                                  onTextChange={(value) => handleTextChange(value, "surname")}
                                  autoComplete="family-name"
                                  borderColor={
                                    formConfig?.colorConfig?.formQuestionColor
                                      ? formConfig?.colorConfig?.formQuestionColor
                                      : defaultColorConfig.formQuestionColor
                                  }
                                />
                              );
                            case "email":
                              return (
                                <Email
                                  value={getFormDataValue() || ""}
                                  onEmailChange={(value: string) => handleEmailChange(value, "email")}
                                  onEmailValidityChange={(isValid: boolean) => setIsEmailValid(isValid)}
                                  borderColor={
                                    formConfig?.colorConfig?.formQuestionColor
                                      ? formConfig?.colorConfig?.formQuestionColor
                                      : defaultColorConfig.formQuestionColor
                                  }
                                />
                              );
                            case "phone":
                              return (
                                <PhoneNumber
                                  title={description ? description : ""}
                                  value={getFormDataValue() || ""}
                                  onPhoneNumberChange={(value) => handlePhoneNumberChange(value, "phone")}
                                  autoComplete="tel"
                                  borderColor={
                                    formConfig?.colorConfig?.formQuestionColor
                                      ? formConfig?.colorConfig?.formQuestionColor
                                      : defaultColorConfig.formQuestionColor
                                  }
                                />
                              );
                            case "checkbox":
                              return (
                                <Checkbox
                                  options={currentQuestion?.formFieldOptions || []}
                                  name={`checkboxGroup${label}`}
                                  selectedValues={getFormDataValue() || []}
                                  onValuesChange={(values) => handleCheckboxChange(values)}
                                />
                              );
                            case "address":
                              return <Address onAddressChange={handleAddressChange} addressValues={addressValues} />;
                            case "textarea":
                              return (
                                <Text
                                  value={getFormDataValue() || ""}
                                  onTextChange={(value) => handleFieldChange(value)}
                                  borderColor={
                                    formConfig?.colorConfig?.formQuestionColor
                                      ? formConfig?.colorConfig?.formQuestionColor
                                      : defaultColorConfig.formQuestionColor
                                  }
                                />
                              );
                            case "yes_no":
                              return (
                                <Radio
                                  options={currentQuestion?.formFieldOptions || []}
                                  onValueChange={(value) => handleFieldChange(value)}
                                  selectedValue={getFormDataValue() || ""}
                                  textColor={
                                    formConfig?.colorConfig?.answerTextColor
                                      ? formConfig?.colorConfig?.answerTextColor
                                      : defaultColorConfig.formQuestionColor
                                  }
                                />
                              );
                            case "buttonChoice":
                              return (
                                <ButtonChoice
                                  options={currentQuestion?.formFieldOptions || []}
                                  onValueChange={(value) => handleButtonChoiceClick(value)}
                                  buttonBackground={
                                    formConfig?.colorConfig?.answerBoxBackgroundColor
                                      ? formConfig?.colorConfig?.answerBoxBackgroundColor
                                      : defaultColorConfig.answerBoxBackgroundColor
                                  }
                                  textColor={
                                    formConfig?.colorConfig?.answerTextColor
                                      ? formConfig?.colorConfig?.answerTextColor
                                      : defaultColorConfig.answerTextColor
                                  }
                                />
                              );
                            case "choice":
                              return (
                                <Choice
                                  options={currentQuestion?.formFieldOptions || []}
                                  choiceValue={getFormDataValue() || "Please Select"}
                                  onValueChange={(value) => handleFieldChange(value)}
                                />
                              );
                            case "number":
                              return (
                                <NumberInput
                                  onTextChange={(value) => handleFieldChange(value)}
                                  value={getFormDataValue() || ""}
                                  clearValue={clearNumberValue}
                                  setClearValue={setClearNumberValue}
                                />
                              );
                            case "currency":
                              return (
                                <Currency
                                  onTextChange={(value) => handleFieldChange(value)}
                                  value={getFormDataValue() || ""}
                                  clearValue={clearNumberValue}
                                  setClearValue={setClearNumberValue}
                                  currency={formConfig?.currency ? formConfig?.currency : "GBP"}
                                  lowestAcceptedValue={
                                    formFieldType.lowestAcceptedValue ? formFieldType.lowestAcceptedValue : 0
                                  }
                                  setDisableNext={setDisableNext}
                                />
                              );
                            default:
                              return null;
                          }
                        })()}
                      </div>
                    </div>
                  </div>

                  <div className="button__div">
                    <button
                      type="button"
                      className="prev_button"
                      id="prev-button"
                      disabled={formConfig!.fields[0].label === currentQuestion.label}
                      onClick={handlePrevious}
                      style={{
                        background: formConfig?.colorConfig?.controlBtnBackgroundColor
                          ? formConfig?.colorConfig?.controlBtnBackgroundColor
                          : defaultColorConfig.controlBtnBackgroundColor,
                        color: formConfig?.colorConfig?.controlBtnTextColor
                          ? formConfig?.colorConfig?.controlBtnTextColor
                          : defaultColorConfig.controlBtnHoverTextColor,
                        border: "none",
                      }}>
                      Previous
                    </button>
                    {!disableNext && (
                      <button
                        id="nextButton"
                        type="button"
                        className="next_button"
                        onClick={handleNextButtonClick}
                        style={{
                          background: formConfig?.colorConfig?.controlBtnBackgroundColor
                            ? formConfig?.colorConfig?.controlBtnBackgroundColor
                            : defaultColorConfig.controlBtnBackgroundColor,
                          color: formConfig?.colorConfig?.controlBtnTextColor
                            ? formConfig?.colorConfig?.controlBtnTextColor
                            : defaultColorConfig.controlBtnHoverTextColor,
                          border: "none",
                        }}>
                        Next
                      </button>
                    )}
                  </div>

                  {currentQuestion?.formFieldType.type !== "buttonChoice" ? <div></div> : <div></div>}
                </form>
              </div>
            </div>
          </div>
        ) : (
          <div className="App">
            <h1>Loading...</h1>
            <PreLoader isLoading={true} />
          </div>
        )}
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
    </>
  );
}
