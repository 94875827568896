export const tryParseInt = (value: string): number | undefined => {
  const parsedValue = parseInt(value, 10);
  return !Number.isNaN(parsedValue) ? parsedValue : undefined;
};

export const getFavicon = (apiKey: string) => {
  switch (apiKey) {
    // Sherry Fitz
    case "slu3oxbDJaO5edsCKJYVW8DZnfjipWojONcjzld8pLysievvd6fIS0w4oNsLi0Tz":
      return [
        { rel: "apple-touch-icon", sizes: "180x180", href: "/apple-touch-sherry-fitz-icon.png" },
        { rel: "icon", type: "image/png", sizes: "32x32", href: "/sherry-fitz-favicon-32x32.png" },
        { rel: "icon", type: "image/png", sizes: "16x16", href: "/sherry-fitz-favicon-16x16.png" },
      ];
      break;
    // Absolute
    case "YksiA5h8Sh_y1H-HD4M7b8JeuqAF4_-5SE6zWFrtinw":
      return [
        { rel: "apple-touch-icon", sizes: "180x180", href: "/apple-touch-icon.png" },
        { rel: "icon", type: "image/png", sizes: "32x32", href: "/favicon-32x32.png" },
        { rel: "icon", type: "image/png", sizes: "16x16", href: "/favicon-16x16.png" },
      ];
      break;
    default:
      return [
        { rel: "apple-touch-icon", sizes: "180x180", href: "/apple-touch-icon.png" },
        { rel: "icon", type: "image/png", sizes: "32x32", href: "/favicon-32x32.png" },
        { rel: "icon", type: "image/png", sizes: "16x16", href: "/favicon-16x16.png" },
      ];
      break;
  }
};
