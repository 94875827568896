import React, { ChangeEvent, useEffect, useState } from "react";
import { tryParseInt } from "../functions/UtilityFunctions";

interface NumberProps {
  onTextChange: (value: number) => void;
  value: string;
  clearValue: boolean;
  setClearValue: React.Dispatch<React.SetStateAction<boolean>>;
}

const NumberInput: React.FC<NumberProps> = ({ onTextChange, value, clearValue, setClearValue }) => {
  const [localValue, setLocalValue] = useState<number | undefined>(0);

  useEffect(() => {
    if (clearValue) {
      setLocalValue(0);
      setClearValue(false);
    }
  }, [clearValue]);

  useEffect(() => {
    const numberValue = tryParseInt(value);
    setLocalValue(numberValue);
  }, [value]);

  const updateNumber = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const numberValue = tryParseInt(newValue);
    setLocalValue(numberValue);
    onTextChange(numberValue ? numberValue : 0);
  };

  return (
    <div className="text-input__container">
      <input type="text" className="text__input" value={localValue} onChange={updateNumber} />
    </div>
  );
};

export default NumberInput;
