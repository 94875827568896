import React, { useEffect, useRef, useState } from "react";
import { tryParseInt } from "../functions/UtilityFunctions";
import CurrencyInput from "react-currency-input-field";
import useFocus from "../hooks/useFocus";

export const useMountEffect = (fun: any) => useEffect(fun, []);

interface CurrencyProps {
  onTextChange: (value: number) => void;
  value: string;
  clearValue: boolean;
  setClearValue: React.Dispatch<React.SetStateAction<boolean>>;
  currency: string;
  lowestAcceptedValue: number;
  setDisableNext: React.Dispatch<React.SetStateAction<boolean>>;
}

const Currency: React.FC<CurrencyProps> = ({
  onTextChange,
  value,
  clearValue,
  setClearValue,
  currency,
  lowestAcceptedValue,
  setDisableNext,
}) => {
  const [inputRef, setInputRef] = useFocus();
  const [localValue, setLocalValue] = useState<string>("0");
  const [intlConfig, setIntlConfig] = useState<{ locale: string; currency: string }>({
    locale: "en-GB",
    currency: "GBP",
  });
  const [inputErrorState, setInputErrorState] = useState({
    showLowestValueWarning: false,
    borderColor: "none",
  });

  useMountEffect(setInputRef);

  useEffect(() => {
    if (value === "0" || value === "" || localValue === "0") {
      setDisableNext(true);
    }
  }, [value, localValue]);

  useEffect(() => {
    setIntlConfig({ locale: "en-GB", currency });
  }, [currency]);

  useEffect(() => {
    if (clearValue) {
      setLocalValue(""); // Clear the input
      onTextChange(0); // Reset the value to 0 in the parent component
      setClearValue(false); // Reset the clearValue flag
    }
  }, [clearValue, onTextChange, setClearValue]);

  useEffect(() => {
    setLocalValue(value); // Sync the local value with the prop
  }, [value]);

  useEffect(() => {
    const numberValue = tryParseInt(localValue);

    if (localValue === "" || (numberValue && numberValue < lowestAcceptedValue)) {
      setInputErrorState({
        showLowestValueWarning: localValue !== "",
        borderColor: localValue !== "" ? "3px solid red" : "none",
      });
      setDisableNext(true);
    } else if (localValue !== "0") {
      setInputErrorState({
        showLowestValueWarning: false,
        borderColor: "none",
      });
      setDisableNext(false);
    }
  }, [localValue, lowestAcceptedValue]);

  const updateNumber = (value: string | undefined) => {
    if (value !== undefined) {
      setLocalValue(value);
      const numberValue = tryParseInt(value);
      onTextChange(numberValue ?? 0);
    } else {
      setLocalValue("");
      onTextChange(0);
    }
  };

  useEffect(() => {
    setInputRef(); // Ensure the input is focused whenever the component mounts or updates
  }, [setInputRef]);

  return (
    <div className="text-input__container">
      {inputErrorState.showLowestValueWarning && (
        <p style={{ marginBottom: "8px", color: "white" }}>Must be greater than {lowestAcceptedValue}</p>
      )}
      <CurrencyInput
        value={localValue}
        className="text__input"
        allowDecimals={false}
        allowNegativeValue={false}
        onValueChange={(value) => updateNumber(value)}
        intlConfig={intlConfig}
        groupSeparator=","
        defaultValue={0}
        placeholder="€0"
        ref={inputRef}
        style={{ border: inputErrorState.borderColor }}
      />
    </div>
  );
};

export default Currency;
