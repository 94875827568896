import { useEffect } from "react";

interface FaviconConfig {
  rel: string;
  sizes?: string;
  type?: string;
  href: string;
}

const useFavicon = (favicons: FaviconConfig[]) => {
  useEffect(() => {
    const head = document.querySelector("head");

    // Remove old favicons
    const oldFavicons = head?.querySelectorAll("link[rel*='icon']");
    oldFavicons?.forEach((oldLink) => head?.removeChild(oldLink));

    // Add new favicons
    favicons.forEach(({ rel, sizes, type, href }) => {
      const link = document.createElement("link");
      link.rel = rel;
      if (sizes) link.setAttribute("sizes", sizes);
      if (type) link.setAttribute("type", type);
      link.href = href;
      head?.appendChild(link);
    });
  }, [favicons]);
};

export default useFavicon;
