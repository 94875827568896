import React, { useEffect } from "react";
import { FormFieldOptions } from "../types/types";

interface RadioProps {
  options: FormFieldOptions[];
  selectedValue: string;
  onValueChange: (value: string) => void;
  textColor: string;
}

const Radio: React.FC<RadioProps> = ({ options, selectedValue, onValueChange, textColor }) => {
  useEffect(() => {}, [selectedValue]);

  return (
    <div>
      {options.map((option) => (
        <label key={option.id} className="radio__label">
          <input
            type="radio"
            name="radio_option"
            value={option.optionLabel}
            checked={option.optionLabel === selectedValue}
            onChange={() => onValueChange(option.optionLabel)}
          />
          <span className="radio_text" style={{ color: textColor }}>
            {option.optionLabel}
          </span>
        </label>
      ))}
    </div>
  );
};

export default Radio;
