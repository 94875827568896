import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState } from "react";
import SuccessComponent from "./components/SuccessComponent";
import useFavicon from "./hooks/useFavicon";
import Form from "./components/Form";
import { getFavicon } from "./functions/UtilityFunctions";

// base url + apiKey + uniqueId

interface FaviconConfig {
  rel: string;
  sizes?: string;
  type?: string;
  href: string;
}

function App() {
  const [backgroundImage, setBackgroundImage] = useState("");
  const [apiKey, setApiKey] = useState("YksiA5h8Sh_y1H-HD4M7b8JeuqAF4_-5SE6zWFrtinw");
  const [favicons, setFavicons] = useState<FaviconConfig[]>([]);
  const formId = "NIK5mTK9nQ";

  useEffect(() => {
    const fetchFavicon = async () => {
      const favicon = await getFavicon(apiKey);
      setFavicons(favicon);
    };

    fetchFavicon();
  }, [apiKey]);

  useFavicon(favicons);

  return (
    <div>
      <Router>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            height: "100vh",
          }}>
          {/* <Link to={`/${apiKey}/${formId}`}>
                 Go to form
               </Link>     */}
          <Routes>
            <Route
              path="/:apiKey/:formId/:uuid?/:applicantId?"
              element={<Form setBackgroundImage={setBackgroundImage} setApiKey={setApiKey} />}
            />
            <Route path="/success" element={<SuccessComponent />} />
            {/* <Route path="/*" element={<PreLoader isLoading={loading}/>} /> */}
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
