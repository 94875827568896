import axios, { AxiosError, AxiosResponse } from "axios";
import { FormSubmit } from "../types/types";

const apiUrl = `${process.env.REACT_APP_BASE_URL}`;

axios.defaults.baseURL = apiUrl;

const responseBody = (response: AxiosResponse) => response.data;

axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    const { data, status } = error.response as AxiosResponse;
    console.error(data, status);

    return Promise.reject(error);
  }
);

const requests = {
  get: (url: string, headers?: object) => {
    return axios.get(url, { headers }).then(responseBody);
  },
  post: (url: string, body: object, headers?: object) => axios.post(url, body, { headers }).then(responseBody),
};

const formConfigApi = {
  get: (
    formId: string,
    uuid: string | undefined | null,
    applicantId: string | undefined | null,
    date: number,
    apiKey: string
  ) =>
    requests.get(`/config/${formId}${uuid ? "/" + uuid : ""}${applicantId ? "/" + applicantId : ""}/${date}`, {
      "x-api-key": apiKey,
    }),
  post: (value: FormSubmit, apiKey: string) => requests.post(`/basic`, value, { "x-api-key": apiKey }),
};

const agent = {
  formConfigApi,
};

export default agent;
