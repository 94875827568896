import React, { useEffect, useState } from "react";
import { FormFieldOptions } from "../types/types";

interface ButtonChoiceProps {
  options: FormFieldOptions[];
  onValueChange: (value: string) => void;
  buttonBackground: string;
  textColor: string;
}

export default function ButtonChoice({ options, onValueChange, buttonBackground, textColor }: ButtonChoiceProps) {
  const [hoveredButton, setHoveredButton] = useState<string | null>(null);
  const sortedOptions: FormFieldOptions[] = [];

  useEffect(() => {
    options.sort(function (a, b) {
      return a.sortOrder - b.sortOrder;
    });
  }, [options]);

  const handleMouseEnter = (optionLabel: string) => {
    setHoveredButton(optionLabel);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    let buttonValue = event.currentTarget.innerHTML;
    onValueChange(buttonValue);
  };

  return (
    <div className="btn-container-column">
      {options.map((option) => {
        const isHovered = hoveredButton === option.optionLabel;

        const buttonStyle = {
          cursor: "pointer",
          backgroundColor: buttonBackground,
          color: textColor,
          fontFamily: "sans-serif",
          fontWeight: "600",
          marginBottom: "5px",
          padding: "20px",
          border: `2px solid ${textColor}`,
        };

        return (
          <button
            type="button"
            className={`btn-custom`}
            key={option.id}
            onClick={handleButtonClick}
            style={buttonStyle}
            onMouseEnter={() => handleMouseEnter(option.optionLabel)}
            onMouseLeave={handleMouseLeave}>
            {option.optionLabel}
          </button>
        );
      })}
    </div>
  );
}
