import React, { useEffect } from "react";
import { FormFieldOptions } from "../types/types";

interface ChoiceProps {
  options: FormFieldOptions[];
  choiceValue: string;
  onValueChange: (value: string) => void;
}

const Choice: React.FC<ChoiceProps> = ({ options, choiceValue, onValueChange }) => {
  const handleChoiceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    choiceValue = event.target.value;
    onValueChange(choiceValue);
  };

  return (
    <div>
      <select value={choiceValue} onChange={handleChoiceChange} className="selector_input">
        <option value="Please Select" disabled>
          Please Select
        </option>
        {options.map((option) => (
          <option key={option.id} value={option.optionLabel} className="selector_options">
            {option.optionLabel}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Choice;
