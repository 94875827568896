import { FormConfig, Field } from "../types/types";

class FormTraverser {
  private formConfig: FormConfig;

  // Store both the current question and its associated branch.
  private currentPath: { question: Field; branch: Field[] }[] = [];

  private currentQuestion: Field | null = null;
  private currentBranch: Field[] = [];

  constructor(config: FormConfig) {
    this.formConfig = config;
    this.currentQuestion = config.fields[0]; // Start with the first question.
  }

  public forward(answer: string | number): Field | null {
    if (!this.currentQuestion) return null;

    const normalizedAnswer = this.normalizeAnswer(answer);

    if (this.hasBranches(this.currentQuestion)) {
      const branch = this.findMatchingBranch(normalizedAnswer);
      if (branch) {
        this.updateStateForBranch(branch); // Move to the branch if matched.
        return this.currentQuestion;
      }
    }

    return this.moveToNextField(); // Move to the next question if no branches match.
  }

  public back(): Field | null {
    if (this.currentPath.length === 0) {
      return this.resetToStart(); // Go back to the start if there's no previous path.
    }

    const previousState = this.currentPath.pop();
    if (!previousState) return null;

    this.currentQuestion = previousState.question; // Restore the previous question.
    this.currentBranch = previousState.branch; // Restore the previous branch context.

    return this.currentQuestion;
  }

  public getCurrentQuestion(): Field | null {
    return this.currentQuestion;
  }

  // Normalize the answer for comparison (e.g., lowercase, remove spaces).
  private normalizeAnswer(answer: string | number): string {
    return answer.toString().toLowerCase().replace(/\s+/g, "");
  }

  // Check if the current question has branches.
  private hasBranches(field: Field): boolean {
    return !!field.branches;
  }

  // Find a matching branch based on the answer provided.
  private findMatchingBranch(answer: string): Field[] | null {
    for (let branch of this.currentQuestion!.branches!) {
      if (branch.evaluator) {
        const normalizedEvaluators = branch.evaluator.map((evaluator: string) => this.normalizeAnswer(evaluator));
        if (normalizedEvaluators.includes(answer)) {
          return branch.fields;
        }
      }
    }
    return null;
  }

  private updateStateForBranch(branch: Field[]): void {
    this.currentPath.push({ question: this.currentQuestion!, branch: this.currentBranch }); // Store current state.
    this.currentBranch = branch; // Move to the new branch.
    this.currentQuestion = branch[0]; // Set the first question of the branch.
  }

  private moveToNextField(): Field | null {
    const nextField = this.getNextField();
    if (nextField) {
      this.currentPath.push({ question: this.currentQuestion!, branch: this.currentBranch }); // Store current state.
      this.currentQuestion = nextField; // Move to the next question.
      return this.currentQuestion;
    }
    this.currentQuestion = null; // No more fields to move to.
    return null;
  }

  // Get the next field to move to, either within the current branch or the main sequence.
  private getNextField(): Field | null {
    if (this.currentBranch.length > 0) {
      return this.getNextFieldInBranch(); // Continue in the current branch.
    }
    return this.getNextFieldInMainSequence(); // Continue in the main sequence.
  }

  // Get the next field within the current branch.
  private getNextFieldInBranch(): Field | null {
    const currentFieldIndex = this.currentBranch.indexOf(this.currentQuestion!);
    return this.currentBranch[currentFieldIndex + 1] || null;
  }

  // Get the next field in the main sequence of the form (outside branches).
  private getNextFieldInMainSequence(): Field | null {
    const currentFieldIndex = this.formConfig.fields.indexOf(this.currentQuestion!);
    return this.formConfig.fields[currentFieldIndex + 1] || null;
  }

  // Reset the form to the initial state (first question, no branch).
  private resetToStart(): Field | null {
    this.currentQuestion = this.formConfig.fields[0];
    this.currentBranch = [];
    this.currentPath = []; // Clear the path history.
    return this.currentQuestion;
  }
}

export default FormTraverser;
